import React, {useContext, useEffect, useRef, useState } from 'react'
import './Form.css'
import Field from '../Field/Field'
import Pill from '../Pill/Pill'
import { addDoc, collection, serverTimestamp } from 'firebase/firestore'
import { db } from '../../firebase/firebase'
import Notif from '../Notif/Notif'
import { FileUploader } from 'react-drag-drop-files'



import {
  ref,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { storage } from '../../firebase/firebase'
import { v4 } from 'uuid'

import { DashboardContext } from '../../Hooks/DashboardContext'




const Form = () => {

  const {setloading}=useContext(DashboardContext)

  const CategorieInput=useRef()

  const [Nom,setNom]=useState('')
  const [Categorie,setCategorie]=useState('')
  const [Categories,setCategories]=useState([])
  const [Description, setDescription] = useState('')
  const [EtatDuProduit, setEtatduProduit] = useState('')
  const [Quantite, setQuantite] = useState('')
  const [PrixVendeur, setPrix] = useState('')
  const [PrixInApp, setPrixInApp] = useState('')
  const [TauxDeMarge, setTauxDeMarge] = useState('')
 
  const [Urls,setUrls]=useState(null)

  const [notif,setnotif]=useState(false)
  const [ErrorMessage,setErrorMessage]=useState()
 //const [active,setFichierAjoute]=useState('')

 


  const [ajoutable,setajoutable]=useState(false)
  const [files,setFiles] = useState(null);

  const [prod,setProd]=useState({})

  const AddProdDetail=(key,valeur)=>{
  
    setProd((prevstate)=>({...prevstate,[key]:valeur}))
  }

 

  const Produit={
    Nom:Nom,
    Categorie : Categorie ,
    Categories: Categories,
    Description :Description,
    UrlsImages:Urls,
    EtatDuProduit :EtatDuProduit,
    Quantite :Quantite,
    PrixVendeur :PrixVendeur,
    PrixInApp:PrixInApp,
    TauxDeMarge :TauxDeMarge,
    createdAt: serverTimestamp(),

}
  
  const handleChange =(files) => {
     setFiles(files) ;

    //setFichierAjoute('Fichierajoute')
  }

  const sender = async ()=>{
    console.log('before',Produit)
    await addDoc(collection(db, "Produits"), Produit)
   
    
  }

  const ResetForm=()=>{
    setErrorMessage("Produit bien ajouté a la base de donnée")
    setnotif(true)
          setNom('')
          setCategorie('')
          setCategories([])
          setDescription('')
          setEtatduProduit('')
          setQuantite('')
          setPrix('')
          setPrixInApp('')
          setTauxDeMarge('')
          setFiles(null)
          setUrls(null) 


  }
  useEffect(() => {
    if (Urls==null) return
    //console.log('la variable urls avant envoi du produit est a ',Urls)
    sender()
    //console.log('la variable urls apres envoi du produit est a ',Urls)
    ResetForm() ;
    (()=>{console.log('after',Produit)})();
   
  }, [Urls])
  
  
  const AjouterUnProduit= async ()=>{
      const readytosend= async(urls)=>{
        setUrls(urls)

        /** Desactivation du Loading quand la requete est traitée  */
        setloading(false)
      }

   
      const uploadFile =() => {
            
       if(files==null) return
        Promise.all(
          [...files].map(img => {
            return new Promise((resolu)=>{
              const imageRef = ref(storage, `Produits/${Nom}/${Nom + v4()}`);

              uploadBytes(imageRef, img).then((snapshot) => {
                getDownloadURL(snapshot.ref).then((url) => {
                  resolu(url)})
                }
              )

              /**Loading en attendant que la requete soit traitée  */
              setloading(true)

            })
              }
          )
        ).then((toutlesresolution)=>readytosend(toutlesresolution)).catch(console.log('en attente de l\'upload des images',[...files]))
      
      }

     
      
      uploadFile()
        /*
        if (files == null) return;
        [...files].forEach(img => {
          
          const imageRef = ref(storage, `Produits/${Nom}/${Nom + v4()}`);
              uploadBytes(imageRef, img).then((snapshot) => {
                getDownloadURL(snapshot.ref).then((url) => {
                  console.log('generer a chaque tour',url)
                  setUrls([...Urls,url])
                //setUrls((prev) => [...prev, url])
                //console.log(Urls)
                
              
          
                });
              });
        })
        }
      */
    
    
      
          //await addDoc(collection(db, "Produits"), Produit)
          
  }


  const verificationVide=(e)=>{

        const valeur=e.currentTarget.value

        const Regex_InputText= new RegExp(/^[a-zA-Z]{3,5}.*$/)
        const Regex_InputNumber= new RegExp(/^[0-9]{2,10}.*$/)
 
       
       /* Test de text */ 
       if (e.currentTarget.type==='text') {
        
       
       if(!Regex_InputText.test(valeur)) 
        {
          //console.log("Le champ "+ e.currentTarget.previousSibling.innerText + " doit etre rempli")
          e.currentTarget.classList.add('invalide')
          setajoutable(false)
        }
        else {
          //console.log(Regex_Input.test(e.currentTarget.value))
          e.currentTarget.classList.remove('invalide')
          setajoutable(true)
        }

      }
        /* Test de nombre */ 
      if (e.currentTarget.type==='number') {
        if(!Regex_InputNumber.test(valeur)) 
          {
            //console.log("Le champ "+ e.currentTarget.previousSibling.innerText + " doit etre rempli")
            e.currentTarget.classList.add('invalide')
            setajoutable(false)
          }
          else {
            //console.log(Regex_Input.test(e.currentTarget.value))
            e.currentTarget.classList.remove('invalide')
            setajoutable(true)
          }
        }   
      
      
  }

  /* Test de d'ajout de categorie */

  const AjouterCategorie=()=>{

      setCategories([...Categories,Categorie])
      CategorieInput.current.value=''
      //console.log(categories)
      
  }

   
  return (
    <>
      
          <Field FieldName={'Nom du produit'} onchange={e=>setNom(e.currentTarget.value)} value={Nom} onblur={verificationVide}/>

        <div id='text_descr'>
            <label htmlFor="textarea"  >description</label>
            <textarea name="" id="textarea" onChange={e=>setDescription(e.currentTarget.value)} value={Description} onBlur={verificationVide}></textarea>
        </div>

        <div style={{display:'flex',justifyContent:"flex-start",alignItems:"flex-start", flexWrap:'wrap',gap:'1rem',flexDirection:'column'}}>


    
            <div style={{display:'flex',justifyContent:"flex-start",alignItems:"flex-end", gap:'1rem'}}>

              
                <Field FieldName={"Catégorie"} onchange={e=>setCategorie(e.currentTarget.value)} inputRef={CategorieInput} onblur={verificationVide} />

                <Pill bgcolor={"#fff"} bordercolor={"transparent"} classe={"btn"} color={"#000"} text={"Ajouter une catégorie"}  onclick={AjouterCategorie}/>



            </div>


            <div style={{display:"flex", flexWrap:'wrap',gap:'1rem'}}>
              {
                Categories.map(
                  (categorie,index)=>{
                    return (
                      <Pill bgcolor={'#000'} bordercolor={"#fff"} color={"#fff"} key={index} text={categorie} onclick={(e) => {
                        setCategories(
                          Categories.filter(elmnt =>
                           elmnt!==e.currentTarget.innerText
                          )
                        );
                      }}/>
                    )
                  }
                )
              }
            </div>


        </div>

        <div>
          <Field FieldName={'Etat du Produit'} onchange={e=>setEtatduProduit(e.currentTarget.value)} value={EtatDuProduit} onblur={verificationVide}/>

          <Field typeInput='number' FieldName={'Quantité'} onchange={e=>setQuantite(e.currentTarget.value)} value={Quantite} onblur={verificationVide}/>

          <Field typeInput='number'  FieldName={'Prix du Vendeur'} onchange={e=>setPrix(e.currentTarget.value)} value={PrixVendeur} onblur={verificationVide}/>
          <Field typeInput='number'  FieldName={'Prix dans l\'App'} onchange={e=>setPrixInApp(e.currentTarget.value)} value={PrixInApp} onblur={verificationVide}/>
          <Field typeInput='number'  FieldName={'Taux de marge'} onchange={e=>setTauxDeMarge(e.currentTarget.value)} value={TauxDeMarge} onblur={verificationVide}/>
            

          <h1 style={{marginBottom:'1rem'}}>Ajouter une image de produit</h1>
          
           
          <div id='FileUploader'>
            <label id='labelDropMoi' htmlFor='dropMoi'>
            <div className='box'><div className='plus'></div></div>
            </label>
            <input type="file" name="fichier" id="dropMoi" multiple onChange={e=>handleChange(e.currentTarget.files)} accept='image/gif, image/png, image/jpeg,image/webp, image/avif'/>
          </div>
          

          <div id='PreviewImg'>

            { /*Images.map((image,index)=>{ return(
                <div className='preview' key={index}><img src={URL.createObjectURL(image)} alt={image.name}/></div>
              )})*/
            }
            {
              files==null?'':[...files].map((image,index)=>{ return(
                <div className='preview' key={index}><img src={URL.createObjectURL(image)} alt={image.name}/></div>
              )})
            }
          </div>

              
        </div>
        <div id="Action">
                        { ajoutable && <Pill text={"Ajoutez un produit"} color={"#000"} bgcolor={"#E8C133"} bordercolor={"transparent"} classe={"btn"} onclick={AjouterUnProduit}/>}

                        <Pill text={"Voir les autres produits"} color={"#fff"} bgcolor={"transparent"} bordercolor={"#e8c133"} classe={"btn"}/>
        </div>

        {notif?<Notif Message={ErrorMessage} onclick={e=>setnotif(false)}/>:''}

    </>
  )

 
}

export default Form