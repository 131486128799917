import React from 'react'
import './Field.css'


const Field = ({FieldName,onchange,inputRef,value,onblur,typeInput}) => {

  //const Regex_Nom= new RegExp(/^[a-z A-Z]{1,10}?$/)
 



  return (
    <div id='Champ'>
        <label htmlFor="Input_Champ">{FieldName}</label>
        <input type={typeInput===''?'text':typeInput} name="field" id="Input_Champ" onChange={onchange} ref={inputRef} value={value} onBlur={onblur}/>
    </div>
  )
}

export default Field