// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
/*For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBrVardxcXxiKEEJAPr7o-ADMN_0owQdyo",
  authDomain: "ornoirmarket.firebaseapp.com",
  projectId: "ornoirmarket",
  storageBucket: "ornoirmarket.firebasestorage.app",
  messagingSenderId: "7434760131",
  appId: "1:7434760131:web:2423c21c948f59a4925776",
  measurementId: "G-MBS3MJD7WY"
};*/ 

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyDjZCrBJuQD5JaZfTcxJA0cRpIcX5DYWF4",
  authDomain: "delivered-1fc62.firebaseapp.com",
  projectId: "delivered-1fc62",
  storageBucket: "delivered-1fc62.appspot.com",
  messagingSenderId: "822600054404",
  appId: "1:822600054404:web:6692d254aac4d7e6ceb4d1",
  measurementId: "G-QRLPBQC69T"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage=getStorage(app);
export default app;

