import React, { createContext} from 'react'
import UseDashboard from './UseDashboard'





export const DashboardContext=createContext()


const DashboardProvider = ({children}) => {
  const Data=UseDashboard()
  return (
    <DashboardContext.Provider value={Data}>
        {children}
    </DashboardContext.Provider>
  )

}

export default DashboardProvider