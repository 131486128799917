import React from 'react'
import './Loading.css'

const Loading = ({classe}) => {
  return (
    <div id='Loading'>
        <div className={`lds-spinner ${classe}`}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
  )
}

export default Loading