import React, { useContext, useEffect } from 'react'
import './Dashboard.css'
import Pill from '../../components/Pill/Pill'
import OrnoirMarket from '../../components/Logo/Logo'
import { onAuthStateChanged ,signOut} from 'firebase/auth'
import {auth, db} from '../../firebase/firebase'

import { useNavigate } from 'react-router-dom'

import Form from '../../components/Form/Form'
import Loading from '../../components/Loading/Loading'
import UseDashboard from '../../Hooks/UseDashboard'
import { DashboardContext } from '../../Hooks/DashboardContext'
import { useRef } from 'react'

//import { collection, getDocs, getDoc} from 'firebase/firestore'



const Dashboard = () => {

    const {loading}=useContext(DashboardContext)
    const DashboardElmnt=useRef()
    useEffect(()=>{
        onAuthStateChanged(auth, (user) => {
            if (user) {
              // User is signed in, see docs for a list of available properties
              // https://firebase.google.com/docs/reference/js/firebase.User
              //const uid = user.uid;
              // ...a decommenter plus tard
              //console.log("uid", uid)
            } else {
              // User is signed out
              // ... a decommenter plus tard
              //console.log("user is logged out")
            }
          });

         /* getDocs(collection(db,"Produits"))
                .then((querySnapshot)=>{              
                    const newData = querySnapshot.docs
                        .map((doc) => ({...doc.data(), id:doc.id }));

                    console.log(querySnapshot)
                    console.log(newData)
                    //setTodos(newData);                
                    //console.log(todos, newData);
                })
        */

    }, [])


    useEffect(() => {
        
        DashboardElmnt.current.classList.toggle('disable')

    }, [loading])
    
    const navigate = useNavigate();

    const handleLogout = () => {               
        signOut(auth).then(() => {
        // Sign-out successful.
            navigate("/");
            //console.log("Signed out successfully")
        }).catch((error) => {
        // An error happened.
        });
    }


    

  return (

    <>
        <div id='Progress'>
            {loading && <Loading/>}
         </div>
        <main id='Dashboard' ref={DashboardElmnt}>

            

            <nav id="navbar">
                <OrnoirMarket/>
                <div id="Tabs">
                    <Pill text={'Produits'} color={"#fff"} bgcolor={'transparent'} bordercolor={'#E8C133'}/>
                    <Pill text={'Statistiques'} color={'#000'} bgcolor={'#E8C133'} bordercolor={'transparent'}/>
                </div>
                
            </nav>
            
            <div id="searchBar">
                <input type="text" name="SearchBar" id="SearchBarInput" />
                <Pill text={"rechercher"} color={'#fff'} bgcolor={'transparent'} bordercolor={"#E8C133"} />
                
            </div>

            <section>
                <div id='SideBar'>
                    <div id="Database">
                        <span className='heading'>Database</span>
                        <Pill text={'Database Produits'} color={"#000"} bgcolor={"#fff"} bordercolor={"transparent"} />
                    </div>
                
                    <div id="connexionStatus">
                        <div id="profil"></div>
                        <Pill text={'deconnexion'} classe={"btn"} color={"#fff"} bgcolor={"transparent"} bordercolor={'#E8C133'} onclick={handleLogout}/>
                    </div>
                </div>
                <div id='Main'>
                    <div id="PathLocation" className='heading'>
                        Database Produits {'>'}
                    </div>
                    <div id="CurrentActivity">

                        <Form/>{/**/}

                    
                        
                        {/* <div id="Action">
                            <Pill text={"Ajoutez un produit"} color={"#000"} bgcolor={"#E8C133"} bordercolor={"transparent"} classe={"btn"}/>
                            <Pill text={"Voir les autres produits"} color={"#fff"} bgcolor={"transparent"} bordercolor={"#e8c133"} classe={"btn"}/>
                        </div> */}
                    
                    </div>
                </div>
            </section>

        </main>
    </>
  )
}

export default Dashboard