import React from 'react'
import './Logo.css'

const Logo = () => {


  return (
    <div id='LogoText'>

        <span id='Ornoir'>Ornoir </span>
        <span id='Market'>Market</span>
        
    </div>
  )
}

export default Logo