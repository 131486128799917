//import React from 'react'
import { useState } from 'react'


export default function UseDashboard() {

const [loading,setloading]=useState(false)

  return {
    loading,
    setloading
  }

}
